import React, { forwardRef, RefObject } from 'react'
import { MediaFieldType } from '@portfolio/models'
import { styled, useMediaQuery } from '@portfolio/styles'
import { Button, Container, Media, Video } from '@portfolio/components'
import { useTranslation } from 'react-i18next'
import { Pause, Play } from '@portfolio/icons'

const hidingAnimation = {
  transition: 'transform .7s, opacity .7s',

  variants: {
    hide: {
      true: {
        transform: 'translateY(20px)',
        opacity: '0',
      },
      false: {
        transform: 'translateY(0)',
        opacity: '1',
      },
    },
  },
}

const Slide = styled('div', {
  color: '$white',
  display: 'grid',
  gap: '$32',
  gridTemplateRows: 'max-content',
  alignItems: 'center',
  cursor: 'pointer',
  '-webkit-tap-highlight-color': 'transparent',
  variants: {
    inView: {
      false: {
        pointerEvents: 'none',
        cursor: 'auto',
      },
    },
  },
})

const MediaWrap = styled('div', {
  overflow: 'hidden',
  borderRadius: '$r3_mobile',
  '@lg': {
    borderRadius: '$r3',
  },
  transform: 'translateZ(0)',
  gridColumn: 1,
  gridRow: 1,
})

const ContentWrap = styled('div', {
  display: 'grid',
  alignContent: 'flex-end',
  gridColumn: 1,
  gridRow: 1,
  height: '100%',
  paddingBottom: '$32',
  gap: '$24',
  zIndex: '$content',
  overflow: 'hidden',
  justifyContent: 'center',
  justifyItems: 'center',
  borderRadius: 'calc($r3_mobile - 1px)',
  '@lg': {
    borderRadius: 'calc($r3 - 1px)',
  },
  transform: 'translateZ(0)',
  transition: 'opacity .7s',
  background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))',

  variants: {
    hide: {
      true: {
        opacity: '0.75',
      },
      false: {
        opacity: '1',
      },
    },
  },

  '@md': {
    gap: '$32',
  },
})

const LogoWrap = styled('div', {
  display: 'grid',
  justifySelf: 'center',
  maxWidth: 120,
  '@lg': {
    overflow: 'hidden',
    height: 50,
    maxWidth: 180,
    width: '100%',
  },

  ...hidingAnimation,
})

const TextWrap = styled(Container, {
  ...hidingAnimation,
})

const Name = styled('div', {
  projectFont: 'body03',
  textAlign: 'center',
})

const Position = styled('div', {
  projectFont: 'body03',
  textAlign: 'center',
  color: '$white_05',
})

const ControlButton = styled(Button, {
  display: 'flex',
  margin: '0 auto',
  projectFont: 'ui01',
  color: '$white',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$8',
  zIndex: '$content',

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})

type VideoTestimonialProps = {
  media?: MediaFieldType
  personName?: string
  personPosition?: string
  logo?: MediaFieldType
  priority?: boolean
  ref: RefObject<any> | ((node?: Element | null | undefined) => void)
  play: boolean
  canHideControlsForVideo?: boolean
  inView: boolean
  handleOnEnded: () => void
  handleSlidePlayPause: () => void
  sectionVideos?: boolean
}

export const VideoTestimonial = forwardRef<
  HTMLDivElement,
  VideoTestimonialProps
>(
  (
    {
      media,
      personName,
      personPosition,
      logo,
      play,
      canHideControlsForVideo,
      priority,
      handleOnEnded,
      handleSlidePlayPause,
      inView,
      sectionVideos,
    },
    ref,
  ) => {
    const isMd = useMediaQuery('md')
    const { t } = useTranslation('videoTestimonials')
    const hasMobileVideo =
      media?.mediaPayload.type === 'mux-video' && media.mediaPayload.mobileVideo

    return (
      <Slide onClickCapture={handleSlidePlayPause} ref={ref}>
        {media && (
          <MediaWrap>
            {media?.mediaPayload.type === 'mux-video' ? (
              <Media
                {...media}
                disablePlaceholderBackground
                hardcropRatio="portrait2"
                layout="responsive"
                videoOverride={(video) => {
                  return (
                    <Video
                      alt={media?.alt}
                      firstFrame={video.firstFrame}
                      width={video.width}
                      height={video.height}
                      muted={false}
                      loop={false}
                      src={video.mp4}
                      play={
                        hasMobileVideo ? play && inView && isMd : play && inView
                      }
                      onEnded={handleOnEnded}
                      layout="responsive"
                      priority={priority}
                      sizes="(min-width: 1000px) 340px, (min-width: 740px) 300px,  224px"
                    />
                  )
                }}
                videoOverrideMobile={(video) => {
                  return (
                    <Video
                      alt={media?.alt}
                      firstFrame={video.firstFrame}
                      width={video.width}
                      height={video.height}
                      muted={false}
                      loop={false}
                      src={video.mp4}
                      play={
                        hasMobileVideo
                          ? play && inView && !isMd
                          : play && inView
                      }
                      onEnded={handleOnEnded}
                      layout="responsive"
                      priority={priority}
                      sizes="(min-width: 1000px) 340px, (min-width: 740px) 300px,  224px"
                    />
                  )
                }}
              />
            ) : (
              <Media
                {...media}
                hardcropRatio="portrait2"
                layout="responsive"
                sizes="(min-width: 740px) 240px, (min-width: 1000px) 486px, 290px"
              />
            )}
          </MediaWrap>
        )}
        <ContentWrap hide={canHideControlsForVideo}>
          {sectionVideos && isMd && (
            <LogoWrap hide={canHideControlsForVideo}>
              {logo && (
                <Media
                  {...logo}
                  disablePlaceholderBackground
                  layout="fill"
                  objectFit="contain"
                />
              )}
            </LogoWrap>
          )}

          <TextWrap hide={canHideControlsForVideo}>
            {personName && <Name>{personName}</Name>}
            {personPosition && <Position>{personPosition}</Position>}
          </TextWrap>

          {!canHideControlsForVideo ? (
            <ControlButton>
              {t('play')}
              <Play />
            </ControlButton>
          ) : (
            <ControlButton>
              {t('pause')}
              <Pause />
            </ControlButton>
          )}
        </ContentWrap>
        {sectionVideos && !isMd && (
          <LogoWrap hide={canHideControlsForVideo && isMd}>
            {logo && (
              <Media
                {...logo}
                disablePlaceholderBackground
                layout="intrinsic"
              />
            )}
          </LogoWrap>
        )}
      </Slide>
    )
  },
)
