import { FC, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { SectionVideoTestimonialsProps } from '@portfolio/models'
import { styled, useMediaQuery } from '@portfolio/styles'
import { SectionWrapper, Slider } from '@portfolio/components'
import { VideoTestimonial } from './VideoTestimonial'

const StyledSectionWrapper = styled(SectionWrapper, {
  background: '$darkThemeBackground',
  color: '$white',
  variants: {
    spacing: {
      inner: {
        padding: '$20 0',
      },
      outer: {},
    },
  },
})

const Title = styled('h2', {
  textAlign: 'center',
  marginBottom: '$96',
  padding: '0 $20',
  maxWidth: '1100px',
  marginLeft: 'auto',
  marginRight: 'auto',

  '@md': {
    marginBottom: '$120',
  },

  variants: {
    appearance: {
      big: {
        projectFont: 'heading02',
      },
      small: {
        projectFont: 'heading04',
      },
    },
  },
})

export const SectionVideoTestimonials: FC<SectionVideoTestimonialsProps> = ({
  titleAppearance = 'small',
  spacing = 'inner',
  title,
  slides,
  priority,
}) => {
  const isMd = useMediaQuery('md')

  const [currentVideoPlayingIndex, setCurrentVideoPlayingIndex] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    // when the view shifts between mobile and desktop, we want to pause videos to prevent double play
    setCurrentVideoPlayingIndex(undefined)
  }, [isMd])

  const handleSlidePlayPause = (videoIndex: number) => {
    if (currentVideoPlayingIndex === videoIndex) {
      setCurrentVideoPlayingIndex(undefined)
    } else {
      setCurrentVideoPlayingIndex(videoIndex)
    }
  }

  const handleOnEnded = () => {
    setCurrentVideoPlayingIndex(undefined)
  }

  const canHideControlsForVideo = (videoIndex: number) => {
    if (currentVideoPlayingIndex === undefined) return false
    return currentVideoPlayingIndex === videoIndex
  }

  const handleSlideIntersectionChange = (index: number, inView: boolean) => {
    if (!inView && index === currentVideoPlayingIndex) {
      setCurrentVideoPlayingIndex(undefined)
    }
  }

  return (
    <StyledSectionWrapper
      type={spacing === 'inner' ? 'NO_MARGIN' : 'DEFAULT'}
      spacing={spacing}
    >
      {title && <Title appearance={titleAppearance}>{title}</Title>}

      <Slider isVideo={true}>
        {slides &&
          slides.map(({ personPosition, personName, media, logo }, index) => (
            <InView
              threshold={0.6}
              key={`${index}-${personName}`}
              onChange={(inView) =>
                handleSlideIntersectionChange(index, inView)
              }
            >
              {({ inView, ref }) => (
                <VideoTestimonial
                  ref={ref}
                  media={media}
                  personName={personName}
                  personPosition={personPosition}
                  logo={logo}
                  priority={priority && index <= 3}
                  play={index === currentVideoPlayingIndex}
                  canHideControlsForVideo={canHideControlsForVideo(index)}
                  inView={inView}
                  handleOnEnded={handleOnEnded}
                  handleSlidePlayPause={() => {
                    if (inView) {
                      handleSlidePlayPause(index)
                    }
                  }}
                  sectionVideos
                />
              )}
            </InView>
          ))}
      </Slider>
    </StyledSectionWrapper>
  )
}
